import React, { PureComponent } from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import { WidgetsPageLayout } from '../components/layouts'

class WidgetsPageTemplate extends PureComponent {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageWidgets')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return <WidgetsPageLayout page={page} site={site} sitemap={sitemap} />
    }
}

export default WidgetsPageTemplate

export const pageQuery = graphql`
    query WidgetsPageBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                description
                siteUrl
                twitterHandle
            }
        }
        contentfulPageWidgets(slug: { eq: $slug }) {
            __typename
            title
            slug
            group {
                title
                slug
                contentful_id
                userSection
            }
            userSection
            sections {
                name
                fullWidth
                widgets {
                    __typename
                    ... on ContentfulWidgetSocialFeed {
                        contentful_id
                        title
                        slug
                        userSection
                        feed
                    }
                    ... on ContentfulPageGroup {
                        contentful_id
                        title
                        slug
                        userSection
                        headerImage {
                            file {
                                url
                            }
                        }
                        image {
                            file {
                                url
                            }
                        }
                        imagePosition
                        groups {
                            __typename
                            title
                            slug
                        }
                        page__generic {
                            __typename
                            title
                            slug
                            userSection
                        }
                        page__group {
                            __typename
                            title
                            slug
                            userSection
                        }
                        page__widgets {
                            __typename
                            title
                            slug
                            userSection
                        }
                    }
                    ... on ContentfulWidgetSearchPanel {
                        contentful_id
                        title
                        description {
                            description
                        }
                        bgImage {
                            title
                            file {
                                url
                            }
                        }
                        showCategories
                        featureList {
                            __typename
                            ... on ContentfulWidgetStoryBlock {
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                story {
                                    __typename
                                    slug
                                    layout {
                                        layout
                                    }
                                }
                            }
                            ... on ContentfulWidgetLinkBlock {
                                __typename
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                featureLink: link {
                                    __typename
                                    ... on ContentfulPageGeneric {
                                        title
                                        slug
                                        userSection
                                        layout {
                                            layout
                                        }
                                    }
                                    ... on ContentfulPageGuideline {
                                        title
                                        slug
                                    }
                                    ... on ContentfulWidgetLink {
                                        text
                                        url
                                    }
                                    ... on ContentfulPageWidgets {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroup {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                                icon
                            }
                        }
                    }
                    ... on ContentfulWidgetStoryBlock {
                        contentful_id
                        title
                        summary {
                            summary
                        }
                        backgroundImage {
                            title
                            file {
                                url
                            }
                        }
                        donateAmount
                        story {
                            __typename
                            slug
                            layout {
                                layout
                            }
                            pageDonation {
                                slug
                            }
                        }
                        recurring
                        donateReason
                    }
                    ... on ContentfulWidgetHero {
                        contentful_id
                        title
                        searchText
                        type
                        summary {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                        backgroundImage {
                            title
                            file {
                                url
                            }
                        }
                        backgroundVideoUrl
                        featureList {
                            __typename
                            ... on ContentfulWidgetStoryBlock {
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                story {
                                    __typename
                                    slug
                                    layout {
                                        layout
                                    }
                                }
                            }
                            ... on ContentfulWidgetLinkBlock {
                                __typename
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                featureLink: link {
                                    __typename
                                    ... on ContentfulPageGeneric {
                                        title
                                        slug
                                        userSection
                                        layout {
                                            layout
                                        }
                                    }
                                    ... on ContentfulPageGuideline {
                                        title
                                        slug
                                    }
                                    ... on ContentfulWidgetLink {
                                        text
                                        url
                                    }
                                    ... on ContentfulPageWidgets {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroup {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                                icon
                            }
                        }
                        link {
                            text
                            url
                        }
                        linkColour
                    }
                    ... on ContentfulWidgetLinks {
                        __typename
                        title
                        description {
                            description
                        }
                        backgroundColor
                        textColor
                        imagePosition
                        image {
                            title
                            file {
                                url
                            }
                        }
                        links {
                            __typename
                            ... on ContentfulPageGeneric {
                                title
                                slug
                                userSection
                                layout {
                                    layout
                                }
                            }
                            ... on ContentfulPageGuideline {
                                title
                                slug
                            }
                            ... on ContentfulWidgetLink {
                                text
                                url
                            }
                        }
                    }
                    ... on ContentfulWidgetRichText {
                        contentful_id
                        content {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                    }
                    ... on ContentfulWidgetLinkBlock {
                        __typename
                        title
                        slug
                        summary {
                            summary
                        }
                        backgroundImage {
                            file {
                                url
                            }
                        }
                        buttonText
                        buttonLink: link {
                            __typename
                            ... on ContentfulPageGeneric {
                                title
                                slug
                                userSection
                                layout {
                                    layout
                                }
                            }
                            ... on ContentfulPageGuideline {
                                title
                                slug
                            }
                            ... on ContentfulWidgetLink {
                                text
                                url
                            }
                            ... on ContentfulPageWidgets {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGroup {
                                title
                                slug
                                userSection
                            }
                        }
                    }
                    ...ImpactWidget
                }
            }
            showSocialShare
            metaDescription {
                metaDescription
            }
        }
        allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageCustom {
                            slug
                            title
                            contentful_id
                            userSection
                        }
                        ... on ContentfulPageDirectoryOfServices {
                            id
                            title
                        }
                        ... on ContentfulPageDonation {
                            slug
                            title
                            contentful_id
                        }
                        ... on ContentfulPageGeneric {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroup {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGuideline {
                            title
                            slug
                        }
                        ... on ContentfulPageWidgets {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
